import React, {useEffect, useState} from 'react'
import './App.css'
import {Alert, Button, Dropdown, Layout, MenuProps, Modal, Tag, theme} from 'antd'
import {Content, Header} from 'antd/es/layout/layout'
import LoginButton from './components/LoginButton'
import UserProfile from './components/UserProfile'
import {useAuth0} from '@auth0/auth0-react'
import {io} from 'socket.io-client'
import {Api} from './api'
import {generateUUID, isMobile, measureLatency, readChunks} from './utils'
import MarkdownPreview from '@uiw/react-markdown-preview';
import logo from './logo.svg'
import JobApplicationForm from "./components/CreateSessionForm";
import {SettingOutlined} from "@ant-design/icons";
import {jwtDecode} from 'jwt-decode';
import {useLocation} from "react-router-dom";
import Onboarding from "./components/Onboarding";
import NewAppPromoBanner from "./components/NewAppPromoBanner";


const socket = io('https://api.interviewcopilot.io', { autoConnect: false });
// const socket = io('http://localhost:4000', { autoConnect: false });

let mediaRecorder: MediaRecorder;
function App() {
  const { isAuthenticated, getAccessTokenSilently, user, logout, loginWithRedirect } = useAuth0();
  const [me, setMe] = useState<any>(null)
  const [ isSessionActive, setIsSessionActive ] = useState(false)
  const [api, setApi] = useState<any>(null)
  const [isRecording, setIsRecording] = useState(false)
  const [answer, setAnswer] = useState('')
  const [isAnswering, setIsAnswering] = useState(false)
  const [isSessionLoading, setIsSessionLoading] = useState(false)
  const [isNotSupported, setIsNotSupported] = useState(false)
  const [isMicDisabled, setIsMicDisabled] = useState(false)
  const [isAnotherDeviceRecording, setIsAnotherDeviceRecording] = useState(false)
  const [minutesAlert, setMinutesAlert] = useState(-1)
  const [isMainBtnDisabled, setIsMainBtnDisabled] = useState(true)
  const [isCreateSessionModalOpen, setIsCreateSessionModalOpen] = useState(false)
  const [isRecordingDeviceModalOpen, setIsRecordingDeviceModalOpen] = useState(false)
  const [minutes, setMinutes] = useState({minutesUsed: 0, minutesTotal: 0})
  const [wakeLock, setWakeLock] = useState(null);
  const [isScreenVisible, setIsScreenVisible] = useState(true)
  const [isRecordingDeviceScreenVisible, setIsRecordingDeviceScreenVisible] = useState(true)
  const [isPingDifference, setIsPingDifference] = useState(false)
  const [isAuthChecking, setIsAuthChecking] = useState(false)
  const location = useLocation()


  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userCode = params.get('user_code');
    if (userCode && !isAuthenticated) {
      setIsAuthChecking(true)
      loginWithRedirect().then(() => setIsAuthChecking(false))
    }
  }, [isAuthenticated, location.search, loginWithRedirect]);


  useEffect( () => {
    let latencyInterval: any;
    if (isAuthenticated) {
      (async () => {
        setIsSessionLoading(true)
        const token = await getAccessTokenSilently()
        const api = new Api(token)
        const socketClientId = sessionStorage.getItem('socketClientId') ? sessionStorage.getItem('socketClientId')! : generateUUID()
        sessionStorage.setItem('socketClientId', socketClientId)
        socket.auth = { token, clientId: generateUUID() }
        socket.connect();
        setApi(api)
        const me = await api.getCurrentUser()
        setMe(me)
        const [session, minutes] = await Promise.all([api.getSession(), api.getCurrentUserMinutes()])
        if (session) {
          setIsSessionActive(true)
        }
        setIsSessionLoading(false)
        setMinutes(minutes)

        latencyInterval = setInterval(async () => {
          try {
            const latency = await measureLatency(api.ping)
            socket.emit('connectionQuality', latency)
          } catch (e) {
            if (latencyInterval) {
              clearInterval(latencyInterval)
            }
          }
        }, 30000)

      })()
    }

    return () => latencyInterval && clearInterval(latencyInterval)
  }, [getAccessTokenSilently, isAuthenticated])


  useEffect( () => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions before the component unloads
      stopRecording();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);



    socket.on('joinSession', () => {
      setIsSessionActive(true)
    })

    socket.on('notifications', (json: string) => {
      const data: any = JSON.parse(json)
      if (data.type) {
        if (data.type === 'anotherDeviceStartRecording') {
          setIsAnotherDeviceRecording(true)
          setIsMainBtnDisabled(false)
        }
        else if (data.type === 'anotherDeviceStopRecording') {
          setIsAnotherDeviceRecording(false)
          setIsMainBtnDisabled(true)
        }
        else if (data.type === 'minutesLeft') {
          setMinutesAlert(data.minutes)
          if (data.minutes === 0) {
            stopRecording()
            setIsSessionActive(false)
          }
        } else if (data.type === 'sessionCompletedOnAnotherDevice') {
          stopRecording()
          setAnswer('')
          setIsSessionActive(false)
        } else if (data.type === 'recordingDeviceDisconnected') {
          setIsAnotherDeviceRecording(false)
          setIsRecordingDeviceModalOpen(true)
        } else if (data.type === 'recordingDeviceScreenNotVisible') {
          setIsRecordingDeviceScreenVisible(false)
        } else if (data.type === 'pingTimeDifferenceInPerformance') {
          const item = sessionStorage.getItem('pingDifferenceInPerformance')
          if (!item && !isMobile()) {
            setIsPingDifference(true)
          }
        }
      }
    })

    socket.on('command', async (json: string) => {
      const data: any = JSON.parse(json)
      if (data.type === 'sendAudioImmediately') {
          if (mediaRecorder?.state === "recording") {
            mediaRecorder.requestData()
          }
          // socket.emit('sendAudioImmediatelyCompleted')
        }
      else if (data.type === 'restartAudio') {
          await restartRecording()
        }
    })

    return () => {
      socket.off('joinSession')
        socket.off('notifications')
      socket.off('command')
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!MediaRecorder.isTypeSupported('audio/webm;codecs=opus') && !MediaRecorder.isTypeSupported('audio/mp4')) {
      setIsNotSupported(true)
    }
  }, [])

  useEffect(() => {

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsScreenVisible(false);
        console.log('Screen is locked or app is in background');
        releaseWakelock()
        // You can add additional logic here, like pausing the recording

      } else {
        setIsScreenVisible(true);
        console.log('Screen is visible');
        wakeLockSetup()

        // You can add additional logic here, like resuming the recording
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (isMobile()) {
    if (isScreenVisible) {
      socket.emit('screen', 'visible')
    } else {
      socket.emit('screen', 'notVisible')
    }
    }

  }, [isScreenVisible]);


  const sendRecording = (data: Blob) => {
    socket.emit('audioData', {type: data.type, data: data, timestamp: Date.now()})
  }

  const handleModalOk = async (sessionSettings: any) => {
      setIsSessionLoading(true)
      await createSession(sessionSettings)
      setIsSessionLoading(false)
      setIsCreateSessionModalOpen(false)
  }

  const handleModalCancel = () => {
    setIsCreateSessionModalOpen(false)
  }

  const createSession = async (sessionSettings?: any) => {
    try {
      await updateToken(api)
      const session = await api.createSession(user, sessionSettings)
      if (session) {
        setIsSessionActive(true)
        socket.emit('sessionCreated')
    }
      } catch (e: any) {
        if (e?.message === 'No recording minutes') {
          setMinutesAlert(0)
        }
        // todo handle other errors
      }
  }

  const endSession = async () => {
    await updateToken(api)
    await api.deleteSession()
    socket.emit('sessionCompleted')
    await stopRecording()
    setAnswer('')
    setIsSessionActive(false)
    setIsAnotherDeviceRecording(false)
  }

  const wakeLockSetup = async () => {
    if ('wakeLock' in navigator ?? isMobile()) {
      try {
        // @ts-ignore
        const wakeLockObj = await navigator.wakeLock.request('screen');
        setWakeLock(wakeLockObj);
        socket.emit('wakeLock', 'active')
        console.log('Wake Lock is active');
      } catch (err) {
        socket.emit('wakeLock', 'error')
        // @ts-ignore
        console.error(`Wake Lock error: ${err?.name}, ${err?.message}`);
      }
    } else {
      socket.emit('wakeLock', 'notSupported')
      console.log('Wake Lock API not supported');
    }

  }

  const restartRecording = async () => {
    console.log('restart recording')
    await stopRecording()
    console.log('recording stopped')
    await startRecording()
    console.log('recording started')
  }

  const startRecording = async () => {
    await wakeLockSetup();

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

      const options = {
        mimeType: MediaRecorder.isTypeSupported('audio/webm;codecs=opus') ? 'audio/webm;codecs=opus' : 'audio/mp4',
      }

      mediaRecorder = new MediaRecorder(stream, options)

      setIsRecording(true)

      setTimeout(() => {
        setIsMainBtnDisabled(false)
      }, 1200)

      mediaRecorder.start(600);

      socket.emit('startRecording')

      mediaRecorder.ondataavailable = async (e) => {
        if (e.data.size > 0) {
          sendRecording(e.data);
        }
      }

    }
    catch (err: any) {
      if (err.name === 'NotAllowedError') {
        setIsMicDisabled(true)
      }
      console.log('Recording error', err)
    }
  }

  const releaseWakelock = async () => {
    if (wakeLock) {
      try {
        // @ts-ignore
        await wakeLock.release();
        setWakeLock(null);
        console.log('Wake Lock has been released');
      } catch (err) {
        // @ts-ignore
        console.error(`Wake Lock release error: ${err.name}, ${err.message}`);
      }
    }
  }


  const stopRecording = async () => {
    await releaseWakelock()
    if (mediaRecorder) {
      if (mediaRecorder?.state === "recording") {
        mediaRecorder.stop();
        socket.emit('stopRecording')
      }
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false)
      setIsMainBtnDisabled(true)
    }
  }


  const updateToken = async (api: any) => {
    const decoded = jwtDecode(api.getApiKey());

    const expirationTime = decoded.exp;

    if (expirationTime) {
       // Convert expiration time from seconds to milliseconds
      const expirationDate = expirationTime * 1000;

      // Get the current date
      const currentDate = Date.now();

      // Calculate the time left in seconds
      const timeLeft = (expirationDate - currentDate) / 1000;

      if (timeLeft < 60) {
        const token = await getAccessTokenSilently()
        api.updateApiKey(token)
      }
    }
  }

  const getAnswer = async () => {
    setAnswer('')
    setIsAnswering(true)
    // mediaRecorder.requestData()
    await updateToken(api)
    const res = await api.getAnswer()
    const reader = res?.body?.pipeThrough(new TextDecoderStream()).getReader();
    if (!reader) {
      return
    }
    for await (const chunk of readChunks(reader)) {
      setAnswer((prevState: any) => `${prevState}${chunk}`)
    }
    setIsAnswering(false)

  }

  // @ts-ignore
  const onClick: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'logOut') {
      logout()
    }
    if (key === 'customerPortal') {
      const portal = await api.createCustomerPortalSession()
    if (portal && portal.redirect_url) {
      window.location.replace(portal.redirect_url)
    }
    }
  };

  let items: MenuProps['items'] = [
    {
      label: `Minutes used: ${minutes?.minutesUsed < minutes?.minutesTotal ? minutes?.minutesUsed : minutes?.minutesTotal} of ${minutes?.minutesTotal}`,
      disabled: true,
      key: 'used'
    },
    {
      type: 'divider',
    },
    {
      label: 'Log out',
      key: 'logOut',
    }
  ];

  if (me && me.customer_id) {
    const newItem = {
      label: 'Manage subscriptions',
      key: 'customerPortal'
    }

    items = [
    ...items.slice(0, 1),
    newItem,
    ...items.slice(1),
  ];
  }


  const getGuide = (step: number = 0) => {
    if (isRecording || isAnotherDeviceRecording || answer) {
      return null
    }

    return <Onboarding step={step} onCreateSession={handleCreateSession} isAuthenticated={isAuthenticated} isSessionCreated={isSessionActive} />
  }

  // const getMobileGuide = () => {
  //   if (isRecording || isAnotherDeviceRecording || answer) {
  //     return null
  //   }
  //
  //   return <MobileGuideText />
  // }

  const handleCreateSession = async () => {
    await updateToken(api)
    const cachedSession = await api.getSession()
    if (cachedSession) {
      setIsSessionActive(true)
    } else {
      setIsCreateSessionModalOpen(true)
    }
  }

  return (
    <Layout>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', flexWrap: 'nowrap',
          justifyContent: 'space-between' }}>
          <div>
            <img src={logo} alt="logo" className="logo"/>
            <span className="logo-title">Interview Copilot</span>
          </div>


          <div className="action-wrapper">
            {isRecording
              ? isMobile(user?.email) && isSessionActive && !isNotSupported && !isAnotherDeviceRecording && <Button type="primary" danger onClick={stopRecording}>Stop recording</Button>
              : isMobile(user?.email) && isSessionActive && !isNotSupported && !isAnotherDeviceRecording && <Button type="primary" onClick={startRecording}>Start recording</Button>}

            {isAnotherDeviceRecording && <Tag color="volcano" style={{padding: 4, fontSize: 14}} className="recording-started-label">🎙️ Another device is recording</Tag>}
            {!isMobile(user?.email) && isSessionActive && !isAnotherDeviceRecording && <Tag color="volcano" style={{padding: 4, fontSize: 14}} className="recording-started-label">👇 Use Mobile to Record Interview</Tag>}
            <span className="margin-right"></span>
            {isAuthenticated && !isSessionActive && <Button loading={isSessionLoading} type="primary" onClick={handleCreateSession}>Create session</Button>}
            {isSessionActive && <Button type="primary"  loading={isSessionLoading} ghost onClick={endSession}>Finish session</Button>}
            <span className="margin-right"></span>
            <Dropdown menu={{ items, onClick }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={(e) => e.preventDefault()}>
                <UserProfile /> {user && <SettingOutlined className="settings-icon"/> }
              </a>
            </Dropdown>
            <span className="margin-right"></span>
            {!isAuthenticated && !isAuthChecking && <><LoginButton /> <span className="margin-right"></span></>}
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 'calc(100vh - 112px)',
            background: colorBgContainer,
          }}
        >

          {!(isAnotherDeviceRecording || isRecording) && <NewAppPromoBanner/>}
          {isAuthenticated && isSessionActive &&
            <Button type="primary" size="large" disabled={isMainBtnDisabled || minutesAlert === 0 || !(isRecording || isAnotherDeviceRecording)} loading={isAnswering} style={{width: '100%'}} onClick={getAnswer}>Get answer</Button>
          }

          {isAuthenticated && isSessionActive && (isRecording || isAnotherDeviceRecording) && !answer && !isAnswering &&
          <div style={{fontSize: 14, lineHeight: '24px', marginTop: 16}}>
            Your Copilot is set and ready! During your interview, when you encounter a challenging question, simply hit the 'Get Answer' button. Interview Copilot will promptly provide you with the best response!
            <div style={{marginTop: 10}}></div>
            🎯 For the best experience, it's <strong>crucial to utilize separate devices for conducting the interview and recording.</strong> This setup ensures our Copilot's exceptional performance and provides the smoothest experience, helping you unlock the full potential of our service!
            <div style={{marginTop: 10}}></div>
            🔗 For setup details and device pairing, check out <a href="https://app.interviewcopilot.io/guide" rel="noreferrer" target="_blank">our guide here.</a> Don't miss a seamless experience – pair devices for Copilot's best support!
            <div style={{marginTop: 20}}></div>
            <h3>First Visit? Try demos below to get a gist of real-time assistance</h3>
            <h4>Question & Answer Session</h4>
            <ul>
              <li>
                With the recording started, role-play as the interviewer.
              </li>
              <li>
                Read through our curated list of common interview questions.<br/>
                  - How do you approach problem-solving in the workplace? <br/>
                  - When you’re balancing multiple projects, how do you keep yourself organized? <br/>
                  - What motivates you?
              </li>
              <li>
                After each question, click "Get Answer" to unveil the response.
              </li>
            </ul>
            <h4>Dive into Mock Interview Videos</h4>
            <ul>
              <li>
                <a href="https://youtu.be/Wj179YFlt9Y" target="_blank" rel="noreferrer">Open pre-recorded short videos (open the link on your laptop),</a> simulating mock interviews.
              </li>
              <li>
                Experience Interview Copilot by clicking "Get Answer" after each question, just like in a real interview.
              </li>
            </ul>
          </div>}

          <MarkdownPreview
            wrapperElement={{"data-color-mode": "light"}}
            style={{fontSize: 16, lineHeight: '24px', marginTop: 16}}
            source={answer} />

          {isMobile() && isSessionActive ? getGuide(2) : getGuide()}
        </Content>



        {/*<Alert*/}
        {/*  message="High usage"*/}
        {/*  description="We're currently experience the huge spike in usage. Please be patient and try again in a hour"*/}
        {/*  type="warning"*/}
        {/*  banner*/}
        {/*  closable={true}*/}
        {/*  onClose={() => setMinutesAlert(-1)}*/}
        {/*  style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}*/}
        {/*/>*/}

        {isNotSupported && <Alert
          message="Browser is not supported"
          description="The current browser does not support functionality. Please use Chrome, Firefox or Safari"
          type="error"
          banner
          style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}
        />}

        {isMicDisabled && <Alert
          message="Allow microphone access"
          description="You need to allow microphone access in you browser to use the app. Please go to setting of your browser, allow microphone access and try starting recording again."
          type="error"
          banner
          closable={true}
          style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}
        />}

        {isRecordingDeviceModalOpen && <Alert
          message="Attention Needed: Recording Device Connection Alert 🚨"
          description={<div>We've detected that your recording device may have disconnected. This can happen due to
            various device or network settings. <br/>To ensure you don't miss out on Interview Copilot's real-time support,
            please check the following:
            <br/>
            <br/>1. Wi-Fi or Data Connection: Ensure your device is connected to a stable Wi-Fi network or has a
            reliable mobile data signal.

            <br/>2. Battery Saver Mode: Check if your device is in Battery Saver Mode, which can restrict background
            activity and affect connectivity.

            <br/>3. Device Settings: Some devices have settings that may disable background data usage for specific
            apps. Ensure these settings allow a browser with Interview Copilot to stay connected.

            <br/>4. Keep the Screen On: Enable the setting to keep your phone screen always on and avoid minimizing the app to prevent it from going into the background.

            <br/>To reestablish connection finish your current session, and refresh the page with the Interview Copilot on your mobile device</div>}
          type="error"
          banner
          closable={true}
          onClose={() => setIsRecordingDeviceModalOpen(false)}
          style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}
        />}

        {minutesAlert !== -1 && minutesAlert !== 0 && <Alert
          message="Low minutes"
          description={`You have ${minutesAlert} minutes left. Please upgrade your plan.`}
          type="warning"
          banner
          closable={true}
          onClose={() => setMinutesAlert(-1)}
          style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}
        />}

        {minutesAlert !== -1 && minutesAlert === 0 && <Alert
          message="No minutes left"
          description={<span>You have no minutes left. Please <a href="https://interviewcopilot.io/#pricing" target="_blank" rel="noopener noreferrer">upgrade your plan</a>.</span>}
          type="error"
          banner
          style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}
        />}

        {!isRecordingDeviceScreenVisible && isAnotherDeviceRecording && <Alert
          message="Important Notice"
          description={<div>
            It appears that the recording device (phone) screen has been locked or the browser
            minimized. This may affect the quality of the interview support.
            <br/>
            <br/>
            For the best experience, please do not lock your phone or minimize the browser
            with Interview Copilot during the entire interview session.
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<FeatureDetection featureName='wakeLock' featureDescription='enable allways on screen' />*/}
          </div>}
          type="warning"
          banner
          closable={true}
          onClose={() => setIsRecordingDeviceScreenVisible(true)}
          style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}
        />}

        {isPingDifference && <Alert
      message="Network Discrepancy Detected"
      description={
        <div>
          <p>We've detected a significant difference in network performance between your phone and laptop. This could mean they are connected to different networks (e.g., your phone might be using 4G while your laptop is on Wi-Fi).</p>
          <p>For the best experience, we recommend:</p>
          <ol>
            <li>Connect your phone to the same Wi-Fi network as your laptop.</li>
            <li>Once connected, please complete your current session.</li>
            <li>Start a new session to enjoy improved performance.</li>
          </ol>
          <p>If you've already addressed this, you can dismiss this message and continue.</p>
        </div>
      }
      type="warning"
      showIcon
      closable
      onClose={() => {
        sessionStorage.setItem('pingDifferenceInPerformance', 'true')
        setIsPingDifference(false)
      }}
      className="centerAlert"
    />}

      </Layout>

      <Modal title={<><span>Session Setting</span> <Tag color="purple">Beta</Tag></>} open={isCreateSessionModalOpen}
             style={{ top: 16 }}
             onCancel={handleModalCancel}
             footer={null}>
        <p style={{marginTop: -4, fontSize: 13}}>Optional: Enhance your Interview Copilot experience by sharing company details, pasting the job description, and specifying your desired position. Crafting personalized responses for your dream job is just a step away!</p>
        <JobApplicationForm onSubmit={handleModalOk} onCancel={() => setIsCreateSessionModalOpen(false)}/>
      </Modal>
    </Layout>
  );
}

export default App;
