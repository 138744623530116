import { useAuth0 } from '@auth0/auth0-react'
import { Avatar } from 'antd'

const UserProfile = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    isAuthenticated ? (
      <Avatar size="large" src={<img src={user?.picture} alt={user?.name}/>}/>
    ) : null
  );
};

export default UserProfile;

// <h2>{user?.name}</h2>
// <p>{user?.email}</p>
