import React from 'react';
import { Card, Typography, Button, Space } from 'antd';
import { RocketOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const NewAppPromoBanner = () => {
  return (
    <Card
      style={{
        background: 'linear-gradient(135deg, #4A90E2 0%, #7C3AED 100%)',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        marginBottom: '20px',
      }}
      bodyStyle={{ padding: '24px' }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Title level={3} style={{ color: 'white', marginBottom: 0, marginTop: 0 }}>
          <RocketOutlined /> Exciting News: try a new version of our app!
        </Title>
        <Paragraph style={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: '16px' }}>
          Experience interview preparation like never before with our new single-device AI assistant.
        </Paragraph>
        <Space>
          <Button
            type="primary"
            size="large"
            href="https://new-app.interviewcopilot.io"
            target="_blank"
            style={{
              background: 'white',
              borderColor: 'white',
              color: '#4A90E2',
              fontWeight: 'bold',
            }}
          >
            Try New App Now
          </Button>
        </Space>
      </Space>
    </Card>
  );
};

export default NewAppPromoBanner;