import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'antd'
import React from 'react'

const LoginButton = () => {
  const { loginWithRedirect, isLoading } = useAuth0();

  return <Button type="primary" loading={isLoading} ghost onClick={() => loginWithRedirect()}>Log in</Button>
}

export default LoginButton;
