import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react'
import {ConfigProvider} from "antd";
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Guide from './Guide'
import * as serviceWorker from './serviceWorker';


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/guide',
    element: <Guide />,
  }
])

serviceWorker.register();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider domain="dev-qtzxgo85oj15qzbz.us.auth0.com"
                   clientId="7r8dEHPi0gRHZcMESX73HsnBPbvVDRG3"
                   useRefreshTokens={true}
                    authorizationParams={{redirect_uri: window.location.origin, audience: "https://icp/api"}}>
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#6415FF'
                },
            }}
        >
    <RouterProvider router={router}/>
        </ConfigProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
