import {Button, Popover, QRCode, Space, Steps, theme, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {AlertOutlined, InfoCircleOutlined, MobileOutlined, RocketOutlined, WifiOutlined} from "@ant-design/icons";
import LoginButton from "./LoginButton";
import {Link} from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const linkStyle = {
  color: '#1b8cf6',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 300,
};

type OnboardingProps = {
  onCreateSession: () => {},
  isAuthenticated: boolean,
  isSessionCreated: boolean,
  step: number
}

const Onboarding = ({ onCreateSession, isSessionCreated, isAuthenticated, step  }: OnboardingProps) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (isAuthenticated && isSessionCreated && current === 0 && step !== 2) {
      setCurrent(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionCreated, isAuthenticated, setCurrent, step]);

  useEffect(() => {
    if (step) {
      setCurrent(step)
    }
  }, [step]);


  const steps = [
  {
    title: 'Create Your Interview Session',
    content:
    <div style={{marginLeft: 16}}>
      <Typography>
        <Title level={5} style={{ marginBottom: 16 }}>
          Welcome to Interview Copilot!
        </Title>
        <Paragraph style={{ fontSize: 16, marginBottom: 24 }}>
          Get ready to ace your interview with our intelligent assistant.
        </Paragraph>

          <Text style={{display: 'block', marginBottom: 8}}>1. To get started, {!isAuthenticated ? 'Log in and ': ''}click the 'Create Session' button below.</Text>
          <Text style={{display: 'block', marginBottom: 8}}>2. This will set up a unique interview space for you.</Text>
          <Text style={{display: 'block', marginBottom: 8}}>3. Once created, you'll be ready to connect your recording device.</Text>

        <div style={{ marginTop: 16, marginBottom: 16 }}>
          {!isAuthenticated && <LoginButton/>}
          {isAuthenticated && !isSessionCreated && <Button
            type="primary"
            icon={<RocketOutlined />}
            size="large"
            onClick={onCreateSession}
            style={{
              backgroundColor: '#6415ff',
              borderColor: '#6415ff',
              paddingLeft: 24,
              paddingRight: 24
            }}
          >
            Create Session
          </Button>}
        </div>
      </Typography>
      </div>,
  },
  {
    title: 'Pair Recording Device',
    content: <div style={{marginLeft: 16}}>
      <Typography>
        <Title level={5} style={{marginBottom: 16}}>
          Let's connect your phone to record the interview audio.{' '}
          <Popover content={<div>
            Using a separate device for recording ensures optimal audio quality and eliminates potential interference.<br/> This setup allows Interview Copilot to capture your responses accurately and provide real-time assistance without disruptions from other applications on your main interview device.<br/>
            <Typography style={{ marginBottom: 8, marginTop: 20, fontWeight: 600 }}>Suggestions for Device Pairing</Typography>
            Consider using your laptop or computer to conduct the interview while using a separate smartphone or tablet to record your audio.
          </div>} title="Why use a second device?" trigger="hover">
            <span style={linkStyle}>
              (Why use a second device?)
            </span>
          </Popover>
        </Title>
        <Text style={{display: 'block', marginBottom: 8}}>1. Scan <strong>the QR code below</strong> with your phone's camera or go to <strong>app.interviewcopilot.io</strong> on your phone's web browser.</Text>
        <Text style={{display: 'block', marginBottom: 8}}>2. Once opened, Log in if required and tap the <strong>"Start Recording" button</strong> on your phone when you're ready to begin.</Text>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <QRCode value="https://app.interviewcopilot.io/?user_code=qrCode" />
        </div>

        <Typography style={{ marginBottom: 16, marginTop: 24, fontWeight: 700 }}>Important Notes:</Typography>
            <Space direction="vertical">
              <Text><MobileOutlined /> After starting the recording, keep the browser open on your phone (don't minimize it)</Text>
              <Text><WifiOutlined /> For the best experience, connect your phone to Wi-Fi</Text>
              <Text><AlertOutlined /> Avoid using headphones during the interview</Text>
            </Space>
      </Typography>
    </div>,
  },
    {
      title: 'You\'re All Set!',
      content:
      <div style={{marginLeft: 16}}>
        <Typography>
          <Title level={5} style={{marginBottom: 16}}>
            You're All Set! Let's Try It Out
          </Title>
          <Text style={{display: 'block', marginBottom: 8}}>Congratulations! You've successfully set up Interview
            Copilot. Here's a quick test drive:</Text>

          <Paragraph strong style={{marginTop: 16, marginBottom: 8}}>Demo: Question & Answer Session</Paragraph>
          <Text style={{display: 'block', marginBottom: 8}}>1.  With the recording started, role-play as the interviewer and ask yourself a common interview
            question (e.g., "How do you approach problem-solving in the workplace?")</Text>
          <Text style={{display: 'block', marginBottom: 8}}>2. Click "Get Answer" to see real-time assistance in
            action.</Text>
          <Text style={{display: 'block', marginBottom: 8}}>3. Try different types of questions to explore the system's
            capabilities.</Text>

          <Paragraph strong style={{marginTop: 16, marginBottom: 8}}>During Your Real Interview:</Paragraph>
          <Text style={{display: 'block', marginBottom: 8}}>• Click "Get Answer" on any device when you need
            assistance.</Text><Text style={{display: 'block', marginBottom: 8}}>• Within seconds, you'll receive the best possible answer to help you navigate the question.</Text>
          <Text style={{display: 'block', marginBottom: 8}}>• Stay natural: use the AI's suggestions as a guide, not a
            script.</Text>

          <Typography style={{marginBottom: 16, marginTop: 24, fontWeight: 700}}>Important Notes:</Typography>
          <Space direction="vertical">
            <Text><InfoCircleOutlined /> If you have any questions or encounter issues, please reach out to our support team via email at <strong>support@interviewcopilot.io</strong> or use the live chat available at interviewcopilot.io.</Text>
        <Text><InfoCircleOutlined /> For a comprehensive setup guide and additional tips, visit <Link to={'https://app.interviewcopilot.io/guide'}>our full guide</Link>.</Text>
          </Space>
        </Typography>
      </div>,
    }
,
]
  ;


  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({key: item.title, title: item.title}));

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <>
      <Steps current={current} items={items}/>
      <div style={contentStyle}>{steps[current].content}</div>
      <div style={{marginTop: 24, display: 'flex', justifyContent: 'flex-end'}}>
        {current > 0 && (
          <Button style={{margin: '0 8px'}} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button onClick={() => next()}>
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default Onboarding;