import {Button, Form, FormInstance} from "antd";
import React from "react";

export const SubmitButton = ({ form }: { form: FormInstance }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values, form]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      Create session
    </Button>
  );
};