import step1 from '../images/step_1.png'
import step_2_1 from '../images/step_2_1.png'
import step_2_2 from '../images/step_2_2.png'
import step_2_3 from '../images/step_2_3.jpeg'
import step_2_4 from '../images/step_2_4.png'
import React from 'react'

function GuideText() {
  return (
    <div className="guide">
      <h2>How to set up your Interview Copilot</h2>
      To make sure the Interview Copilot receive your audio input from an online interview, please follow the instruction to correctly configure your session:
      <h3>Step 1: Log in and create a session</h3>
      <img src={step1} alt="step1" className="step-image-small"/>
      <h3>Step 2: Select your devices for Interview and Recording</h3>
      <p>To ensure a seamless experience with Interview Copilot, it's important to use two separate devices for your interview session.</p>
      <div className="sub-section">
        <h3>Why Use Different Devices?</h3>
        <p className="guide-section">Using one device for your interview (running Zoom, Google Meets, Teams, etc.) and another device to record the interview ensures optimal audio quality and eliminates any potential interference. This setup allows Interview Copilot to capture your responses accurately and provide real-time assistance without disruptions from other applications.</p>
        <h3>Suggestions for Device Pairing</h3>
        <p className="guide-section">Consider using your laptop or computer to conduct the interview while using a separate smartphone or tablet to record your audio.<br/> Here are some simple steps you can follow:</p>
        <h4 className="sub-sub">1. Device for Interview: Laptop/Computer</h4>
        <p className="guide-section">Open your preferred video conferencing application (Zoom, Google Meets, Teams, etc.) for the interview. We assume the Interview Copilot app is already opened and you've also created a session on <strong>Step 1</strong></p>
        <h4 className="sub-sub">2. Device for Recording: Smartphone/Tablet</h4>
        <p className="guide-section">Open the Interview Copilot app, log in with the same account used on your laptop, and begin recording your interview audio. <br/> When you open the Interview CoPilot app on your phone, it automatically syncs with the session you initiated on your laptop in Step 1. This seamless connection ensures that the audio recording aligns with your ongoing interview.</p>
        <div className="steps-image-container">
          <img src={step_2_1} alt="step2_1" className="step-image_phone"/>
          <img src={step_2_2} alt="step2_2" className="step-image_phone"/>
          <img src={step_2_3} alt="step2_3" className="step-image_phone"/>
        </div>
        <p className="guide-section">The Interview Copilot app on your laptop syncs automatically with the phone, indicating that another device is recording. This indicator affirms the synchronization between devices.</p>
        <img src={step_2_4} alt="step2_4" className="step-image"/>
        <p className="guide-section">By using different devices for your interview and recording, you optimize Interview Copilot's performance and accuracy.</p>
      </div>
      <h3>Step 3: Using Interview Copilot During Your Interview</h3>
      <p className="guide-section">Interview Copilot is ready to assist. Once you encounter a challenging question, simply click the "Get Answer" button in the app. This feature is accessible from <strong>either your laptop or phone</strong>, allowing you to choose the device you prefer for obtaining real-time guidance.</p>


      </div>
  )}

export default GuideText;
