

export function readChunks(reader: any) {
  return {
    async* [Symbol.asyncIterator]() {
      let readResult = await reader.read();
      while (!readResult.done) {
        yield readResult.value;
        readResult = await reader.read();
      }
    },
  };
}


export function isMobile(email?: string) {
  if (email && (email === 'mzyatkov@gmail.com' || email === 'clintoneric94@gmail.com' || email === 'ayoubla3@gmail.com' || email === 'sangeet.auro@gmail.com')) {
    return true
  }

  const userAgent = navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const isTablet = /iPad|Android/i.test(userAgent) && window.innerWidth <= 1024;
  const isOrientationMobile = typeof window.orientation !== 'undefined';
  return isMobile || isTablet || isOrientationMobile;
}


export function delay (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export function loadWorker() {
  if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/socketServiceWorker.js').then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(error => {
      console.error('Service Worker registration failed:', error);
    });
  });
}
}


export const measureLatency = async (apiFunc: () => Promise<any>) => {
  const samples = 5;
    let totalLatency = 0;

    for (let i = 0; i < samples; i++) {
      const startTime = Date.now();
      await apiFunc()
      totalLatency += Date.now() - startTime;
    }

    return totalLatency / samples;
}

export const generateUUID = (): string => {
  if (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c): string => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
