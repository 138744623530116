import { Content, Header } from 'antd/es/layout/layout'
import logo from './logo.svg'
import { Layout, theme } from 'antd'
import React from 'react'
import GuideText from './components/GuideText'

function Guide() {


  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return <Layout>
    <Layout>
      <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', flexWrap: 'nowrap',
        justifyContent: 'space-between' }}>
        <div>
          <img src={logo} alt="logo" className="logo"/>
          <span className="logo-title">Interview Copilot</span>
        </div>

      </Header>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 'calc(100vh - 112px)',
          background: colorBgContainer,
        }}
      >

        <GuideText/>

      </Content>
    </Layout>
  </Layout>
}

export default Guide;
