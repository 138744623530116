export class Api {
  private apiKey: string;
  private baseUrl: string = 'https://api.interviewcopilot.io';
  // private baseUrl: string = 'http://localhost:4000/api';
  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  public createSession = (userData: any, sessionSettings?: any) => {
    return fetch(`${this.baseUrl}/sessions`,  {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiKey}`
      },
      body: JSON.stringify({...userData, sessionSettings})
    })
      .then(response => {
        if (response.status === 409) {
          return response.json().then(body => {
            if (body.code === '0001') {
              throw new Error('No recording minutes')
            }
          })
        }
        if (!response.ok) {
          throw new Error('The request to create a session failed')
        }
        return response.json()
      })
      .catch(err => {
        console.error(err);
        throw err
      })
  }


  public getSession = () => {
    return fetch(`${this.baseUrl}/sessions`,  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.ok ? response.json() : Promise.reject(`error: ${response.status}`))
      .catch(err => console.error(err))
  }

  public deleteSession = () => {
    return fetch(`${this.baseUrl}/sessions`,  {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.json())
      .catch(err => console.error(err))
  }

  public getAnswer = async () => {
    try {
      return await fetch(`${this.baseUrl}/answers`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`
        }
      })
    } catch (err) {
       console.error(err)
    }
  }

  public getCurrentUserMinutes = () => {
    return fetch(`${this.baseUrl}/users/me/used-minutes`,  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.ok ? response.json() : Promise.reject(`error: ${response.status}`))
      .catch(err => console.error(err))
  }


  public sendAudioData = (data: any) => {
    const formData = new FormData();
    let fileType;
    if (data.type.includes('webm')) {
      fileType = 'webm';
    } else if (data.type.includes('mp4')) {
      fileType = 'mp4';
    }
  formData.append('audio', data, `audio.${fileType}`); // Ensure the correct filename and type
  formData.append('type', data.type);


    return fetch(`${this.baseUrl}/audio-data`,  {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      },
      body: formData
    })
      // .then(response => {
      //   return response.json()
      // })
      .catch(err => {
        console.error(err);
        throw err
      })
  }

  createCustomerPortalSession = () => {
    return fetch(`${this.baseUrl}/customer-portal-sessions`,  {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.json())
      .catch(err => console.error(err))
  }

  getCurrentUser = () => {
    return fetch(`${this.baseUrl}/users/me`,  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.json())
      .catch(err => console.error(err))
  }

  getApiKey() {
    return this.apiKey
  }

  updateApiKey(token: string) {
    this.apiKey = token
  }

  ping = () => {
    return fetch(`${this.baseUrl}/ping`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    }).then(response => response.ok ? response.json() : Promise.reject(`error: ${response.status}`))
      .catch(err => console.error(err))
  }
}
