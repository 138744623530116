import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Select} from 'antd';
import {SubmitButton} from "./SubmitButton";

const { TextArea } = Input;
const { Option } = Select;
const MAX_LENGTH = 1000;

const JobApplicationForm = ({onSubmit, onCancel}: {onSubmit: any, onCancel: any}) => {
  const [companyDetailsLength, setCompanyDetailsLength] = useState(0);
  const [jobDescriptionLength, setJobDescriptionLength] = useState(0);
  const [candidateDetailsLength, setCandidateDetailsLength] = useState(0);
  const [initialValues, setInitialValues] = useState({})
  const [form] = Form.useForm();

  const languageOptions = [
  { label: 'English', value: 'English' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'French', value: 'French' },
  { label: 'German', value: 'German' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Italian', value: 'Italian' },
];

  useEffect(() => {
    const values = localStorage.getItem('sessionForm')
    if (values) {
      setInitialValues(JSON.parse(values))
    }
  }, []);

  const handleFormAction = (action: any) => {
    const values = form.getFieldsValue()
    localStorage.setItem('sessionForm', JSON.stringify(values))
    action(values)
  }

  const resetForm = () => {
    setInitialValues({})
    setCompanyDetailsLength(0)
    setJobDescriptionLength(0)
    setCandidateDetailsLength(0)
    localStorage.removeItem('sessionForm')
    form.resetFields()
  }


  return (
    <Form
      form={form}
      name="job_application"
      layout="vertical"
      initialValues={initialValues}
      onFinish={() => handleFormAction(onSubmit)}
    >

      <Form.Item name="language" label="Language" initialValue="English">
        <Select>
          {languageOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>


      <Form.Item name="position" label="Position" rules={[]}>
        <Input placeholder="Specify your target role. Helps tailor responses for your specific job" />
      </Form.Item>

      <Form.Item name="companyDetails" label="Company Details" rules={[{ max: MAX_LENGTH }]}>
        <TextArea rows={5} placeholder="Help us tailor our responses to match the culture and expectations of your potential workplace by providing some information about the company."
          value={form.getFieldValue('companyDetails')}
          onChange={(e) => {
            setCompanyDetailsLength(e.target.value.length);
            form.setFieldsValue({ companyDetails: e.target.value });
          }}
        />
        <div style={{textAlign: 'right', color: MAX_LENGTH - companyDetailsLength >= 0 ? 'grey' : 'red'}}>
          { MAX_LENGTH - companyDetailsLength >= 0  ? `${MAX_LENGTH - companyDetailsLength} characters left` : `Maximum ${MAX_LENGTH} characters allowed`}
        </div>
      </Form.Item>

      <Form.Item name="jobDescription" label="Job Description" rules={[{max: MAX_LENGTH}]}>
        <TextArea rows={5}
                  placeholder="Paste the job description here so Interview Copilot can understand the specific requirements and responsibilities of the role you're interviewing for"
                  value={form.getFieldValue('jobDescription')}
                  onChange={(e) => {
                    setJobDescriptionLength(e.target.value.length);
                    form.setFieldsValue({jobDescription: e.target.value});
                  }}
        />
        <div style={{textAlign: 'right', color: MAX_LENGTH - jobDescriptionLength >= 0 ? 'grey' : 'red'}}>
          {MAX_LENGTH - jobDescriptionLength >= 0 ? `${MAX_LENGTH - jobDescriptionLength} characters left` : `Maximum ${MAX_LENGTH} characters allowed`}
        </div>
      </Form.Item>

      <Form.Item name="candidateDetails" label="Your details" rules={[{max: MAX_LENGTH}]}>
        <TextArea rows={5}
                  placeholder="Share more about yourself! Provide insights into your skills, experiences, and what makes you a great fit for the role. The more details you provide, the better Interview Copilot can assist you in crafting personalized responses."
          value={form.getFieldValue('candidateDetails')}
          onChange={(e) => {
            setCandidateDetailsLength(e.target.value.length);
            form.setFieldsValue({ candidateDetails: e.target.value });
          }}
        />
        <div style={{textAlign: 'right', color: MAX_LENGTH - candidateDetailsLength >= 0 ? 'grey' : 'red'}}>
          {MAX_LENGTH - candidateDetailsLength >= 0 ? `${MAX_LENGTH - candidateDetailsLength} characters left` : `Maximum ${MAX_LENGTH} characters allowed`}
        </div>
      </Form.Item>

      <Form.Item>
        <div style={{display: "flex", justifyContent: "flex-end", marginBottom: -16}}>
          <Button onClick={resetForm} >Reset form</Button>
          <Button style={{marginRight: 8, marginLeft: "auto"}} onClick={() => handleFormAction(onCancel)}>Cancel</Button>
          <SubmitButton form={form} />
        </div>
      </Form.Item>
    </Form>
  );
};

export default JobApplicationForm;